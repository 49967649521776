'use client'
import React, { FC } from 'react'
import ShadowLogo from '@/assets/shadow-theatre.svg'
import Image from 'next/image'
import Link from 'next/link'
import { usePathname } from 'next/navigation'
import { Button, Dropdown } from 'antd'
import MenuOutlined from '@ant-design/icons/MenuOutlined'
import Drawer from '@mui/material/Drawer'
import { SignedIn, UserButton, useUser } from '@clerk/nextjs'

let menuItems: any[] = [
	{
		label: 'Home',
		key: 'Home',
		link: '/'
	},
	{
		label: 'Productions',
		key: 'Productions',
		link: '/productions'
	},
	{
		label: 'About',
		key: 'About',
		link: '/about'
	},
	{
		label: 'Tickets/Subscriptions',
		key: 'Tickets-Subscriptions',
		link: '/tickets_subscriptions'
	},
	{
		label: 'Donate',
		key: 'Donate',
		link: '/donate'
	}
]

interface NavLinksProps {
	orientation?: 'horizontal' | 'vertical'
}

export const NavLinks: FC<NavLinksProps> = ({ orientation = 'horizontal' }) => {
	const user = useUser()
	const path = usePathname()
	return (
		<div
			className={`${orientation === 'horizontal' ? 'space-x-16 flex items-center' : 'flex flex-col space-y-10 px-4 py-2'} `}
		>
			{orientation === 'vertical' && (
				<Image src={ShadowLogo} alt='Shadow Theatre Logo' />
			)}
			{menuItems.map(item => (
				<Link
					key={item.link}
					className={`hover:text-shadowTan ${path === item.link ? 'text-shadowTan' : 'text-white'}`}
					href={item.link}
				>
					{item.label}
				</Link>
			))}
			{user && user?.user?.publicMetadata?.role === 'admin' && (
				<Dropdown
					menu={{
						items: [
							{
								key: 'shows',
								label: (
									<Link
										key='admin-shows'
										className={`hover:text-shadowTan bg-none ${path === '/admin/shows' ? 'text-shadowTan' : 'text-black'}`}
										href='/admin/shows'
									>
										Shows
									</Link>
								)
							},
							{
								key: 'cast-and-crew',
								label: (
									<Link
										key='admin-shows'
										className={`hover:text-shadowTan bg-none ${path === '/admin/cast-and-crew' ? 'text-shadowTan' : 'text-black'}`}
										href='/admin/cast-and-crew'
									>
										Cast And Crew
									</Link>
								)
							}
						]
					}}
					placement='bottom'
					arrow={{ pointAtCenter: true }}
				>
					<Button>Admin</Button>
				</Dropdown>
			)}
			<SignedIn>
				<UserButton />
			</SignedIn>
		</div>
	)
}

export default function Header() {
	const [navbarOpen, setNavbarOpen] = React.useState(false)

	return (
		<header className='text-white body-font flex-shrink-0 background-tiled navbar'>
			<div className='container mx-auto flex flex-wrap p-5 md:flex-row'>
				<a
					className='flex title-font font-medium text-white mb-4 md:mb-0 pr-4'
					href='/'
				>
					<Image src={ShadowLogo} alt='Shadow Theatre Logo' />
				</a>
				<div
					className={'md:flex flex-grow items-center relative justify-between'}
					id='example-navbar-danger'
				>
					<nav className='hidden lg:block lg:ml-auto flex flex-wrap items-center text-base justify-center font-semibold pl-7'>
						<NavLinks />
					</nav>
				</div>
				<div className='lg:hidden'>
					<Button
						type='text'
						size={'large'}
						onClick={() => setNavbarOpen(true)}
						icon={
							<MenuOutlined
								style={{ color: '#fff' }}
								onPointerEnterCapture={undefined}
								onPointerLeaveCapture={undefined}
							/>
						}
						className='mr-10'
					/>
				</div>
			</div>
			<Drawer
				anchor='left'
				PaperProps={{
					style: {
						backgroundColor: 'rgb(0,108,117)'
					}
				}}
				open={navbarOpen}
				onClose={() => setNavbarOpen(false)}
			>
				<NavLinks orientation='vertical' />
			</Drawer>
		</header>
	)
}
