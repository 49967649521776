'use client'
import { FaFacebook } from 'react-icons/fa'
import { FaXTwitter } from 'react-icons/fa6'
import { BsInstagram } from 'react-icons/bs'
import { Button, Col, Row } from 'antd'
import useMediaQuery from '@/lib/hooks/windowSizeHook'
export default function Footer() {
	const size = useMediaQuery()
	if (!size) return null

	return (
		<footer className='text-white body-font flex-shrink-0 background-tiled-tan'>
			<Row className='justify-center space-y-6 md:space-y-0 text-center md:text-left md:justify-between md:px-5 py-3 items-center md:h-[130px]'>
				<Col>
					<a href='/'>
						<span className='text-sm text-white'>Shadow Theatre</span>
					</a>
					<p className='text-white text-sm p-0'>in The Varscona Theatre</p>
					<p className='text-white text-sm p-0'>10329 83 Ave NW</p>
					<p className='text-white text-sm p-0'>Edmonton, Alberta, Canada</p>
					<p className='text-white text-sm p-0'>T6E 2C6</p>
				</Col>
				<Col className='space-x-10 h-full flex items-center -translate-y-2'>
					<Button
						type='link'
						icon={
							<FaFacebook
								size={36}
								className='text-white hover:text-shadowBlue transition duration-300'
							/>
						}
						href='https://www.facebook.com/shadowtheatre1'
						target='_blank'
						className='hover:text-shadowBlue'
					/>
					<Button
						type='link'
						href='https://twitter.com/shadowtheatre1'
						target='_blank'
						icon={
							<FaXTwitter
								size={36}
								className='text-white hover:text-shadowBlue transition duration-300'
							/>
						}
						className='hover:text-shadowBlue'
					/>
					<Button
						type='link'
						icon={
							<BsInstagram
								size={36}
								className='text-white hover:text-shadowBlue transition duration-300'
							/>
						}
						href='https://www.instagram.com/shadowtheatre1/'
						target='_blank'
						className='hover:text-shadowBlue'
					/>
				</Col>
				<Col className='h-full flex flex-col justify-center items-center'>
					<p className='text-sm text-white p-0'>780.434.5564</p>
					<a
						href='mailto:info@shadowtheatre.org'
						className='text-sm text-white p-0'
					>
						info@shadowtheatre.org
					</a>
				</Col>
			</Row>
		</footer>
	)
}
