import { useState, useCallback, useEffect } from 'react'

const breakpoints = [576, 768, 992, 1200, 1600]

const useMediaQuery = () => {
	const [currentSize, setCurrentSize] = useState(null)

	const updateSize = useCallback(e => {
		const width = e.target.innerWidth

		setCurrentSize({
			xs: width < breakpoints[0],
			sm: width >= breakpoints[0] && width < breakpoints[1],
			md: width >= breakpoints[1] && width < breakpoints[2],
			lg: width >= breakpoints[2] && width < breakpoints[3],
			xl: width >= breakpoints[3] && width < breakpoints[4],
			xxl: width >= breakpoints[4]
		})
	}, [])

	useEffect(() => {
		window.addEventListener('resize', updateSize)
		updateSize({ target: window }) // check on mount

		return () => window.removeEventListener('resize', updateSize)
	}, [updateSize])

	return currentSize
}

export default useMediaQuery
