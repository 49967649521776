import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@ant-design/nextjs-registry/es/AntdRegistry.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@clerk/nextjs/dist/esm/app-router/client/ClerkProvider.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@clerk/nextjs/dist/esm/client-boundary/controlComponents.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@clerk/nextjs/dist/esm/client-boundary/hooks.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@clerk/nextjs/dist/esm/client-boundary/PromisifiedAuthProvider.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@clerk/nextjs/dist/esm/client-boundary/uiComponents.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@vercel/analytics/dist/react/index.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@vercel/speed-insights/dist/next/index.mjs");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"]}],\"variableName\":\"inter\"}");
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/globals.css");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/Footer.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/Header.tsx")